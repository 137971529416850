import { render, staticRenderFns } from "./integralAcquisition.vue?vue&type=template&id=20b27838&scoped=true&"
import script from "./integralAcquisition.vue?vue&type=script&lang=js&"
export * from "./integralAcquisition.vue?vue&type=script&lang=js&"
import style0 from "./integralAcquisition.vue?vue&type=style&index=0&id=20b27838&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20b27838",
  null
  
)

export default component.exports