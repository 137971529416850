<template>
    <div class="page">
       <div class="background-img">
           <img src="../../assets/images/jifenhuoqushuoming.png" alt="">
           <div class="content">
               <div class="list-cell">
                   <div class="condition">邀请好友注册</div>
                   <div class="add-sub">+50<van-icon name="star" color="#ffad16"/></div>
               </div>
               <div class="list-cell">
                   <div class="condition">确认干活</div>
                   <div class="add-sub">+20<van-icon name="star" color="#ffad16"/></div>
               </div>
               <div class="list-cell">
                   <div class="condition">首次完善个人简历</div>
                   <div class="add-sub">+20<van-icon name="star" color="#ffad16"/></div>
               </div>
               <div class="list-cell">
                   <div class="condition">首次登录</div>
                   <div class="add-sub">+100<van-icon name="star" color="#ffad16"/></div>
               </div>
           </div>
           <div class="bottom">
               <div class="service-avatar">
                   <img src="../../assets/images/famu.png" alt="">
               </div>
               <div class="service-right">
                   <div>快速获取积分</div>
                   <div class="add-wechat">请添加微信客服</div>
                   <div class="wechat-num">wxid8899</div>
               </div>
           </div>
       </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                params: {
                },

            }
        },
        mounted() {

        },
        methods: {

        },
        computed: {

        }
    }

</script>

<style lang="less" scoped>
.background-img{
    position:relative;
    img{
        max-width: 100%;
        height: calc(100vh - 0);
    }

    .content{
        position:absolute;
        max-width: 720px;
        width: 80%;
        top:20%;
        left:10%;
        .list-cell{
            margin: 5px;
            font-weight: 600;
            .condition{
                color: #212121;
            }
            .add-sub{
                color: #3D91EA;
            }
            display: flex;
            justify-content:space-between;
        }


    }
    .bottom{
        position:absolute;
        max-width: 720px;
        width: 80%;
        bottom:15%;
        left:10%;
        height: 75px;
        .service-avatar{
            display: inline-block;
            img{
                width: 65px;
                height: 65px;
            }
        }
        .service-right{
            display: inline-block;
            margin-left: 15px;

            :first-child{
                padding: 0px 0 5px 0;
                color: #212121;
                font-size: 16px;
                font-weight: 600;
            }
            .add-wechat{
                padding: 0px 0 5px 0;
                color: #212121;
                font-size: 14px;
            }
            .wechat-num{
                color: #1083FC;
            }

        }
    }


}

</style>
